@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300;400&family=Open+Sans:wght@400;500;600;700&family=Rubik:wght@500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}
html {
  font-family: "Open Sans", sans-serif;
}
body {
  background-color: #2e2e35;
}

@layer base {
  .link {
    @apply relative before:content-[''] before:absolute before:block before:w-full before:h-[2px] 
    before:bottom-0 before:left-0 before:bg-primary-orange
    before:hover:scale-x-100 before:scale-x-0 before:origin-top-left
    before:transition before:ease-in-out before:duration-500;
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.content-div:hover {
  background-image: linear-gradient(to right, #e4aa48, #e9bb6c) !important;
}
